<template>
  <div class="contact">
    <h1>Contact Page</h1>          
  </div>
</template>

<script>


export default {
  name: 'Contact',
  components: {
    
  }
}
</script>
